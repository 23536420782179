<template>
    <div class="main-content" style="
      padding-bottom: 10vh !important
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 100%;
        background-position: center;
      ">
        <div class="dashboard-banner position-relative">
            <div class="d-flex justify-content-center align-items-end">
            </div>
        </div>
        <div class="m-3 mx-3 mt-4">
            <div class="header">
                <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack">
                <h1 class="main_text text text_main_title">{{ $t("tree") }}</h1>
                <!-- <i class="fa fa-history info-icon" aria-hidden="true"></i> -->
                <!-- <img src="../../../assets/images/etnex_buy/question.png" alt="Info" class="info-icon"> -->
            </div>
            <b-row>
            <b-col md="12">
                <b-card style="overflow-x: auto; overflow-y: auto; background: linear-gradient(90deg, #152331 0%, #000000 100%);color:white">
                <!-- <P class="mb-2 mt-0 text-12 text-left">{{ $t("total_member") }}: {{ this.total_member }}</P> -->
                <v-jstree
                    :data="asyncData"
                    multiple
                    allow-batch
                    whole-row
                    :async="loadData"
                    ref="tree"
                ></v-jstree>
                </b-card>
            </b-col>
            </b-row>
        </div>
    </div>
  </template>
  <script>
  import { getMemberInfo, jstree_ajax_data } from "../../../system/api/api";
  import { handleError } from "../../../system/handleRes";
  import { mapGetters } from "vuex";
  import VJstree from "vue-jstree";
  export default {
    components: {
      ...mapGetters(["lang"]),
      VJstree,
    },
    data() {
      return {
        selected: '24h',
        currentPage: 1,
        PackageRecord: [],
        moreRecords: false,
        etnex_earn: 0,
        total_staking: 0,
        asyncData: [],
        parent: "#",
        uid: "",
        total_member: "0",
      };
    },
    mounted() {
      this.loadItems();
    },

    methods: {
      
      goBack() {
        this.$router.go(-1)
      },

      loadData: async function (oriNode, resolve) {
        var data;
        if (oriNode.data.id) {
            data = await this.getTreeData(oriNode.data.id);
        } else {
            data = await this.loadItems();
        }

        if (data) {
            resolve(data);
        }
      },
      async getTreeData(parent) {
      var result = jstree_ajax_data(
        parent == null ? encodeURIComponent("#") : parent,
        this.uid
      );
      var self = this;
      this.isLoading = true;
      var data = [];
      data = await result
        .then(function (value) {
          var tmpData = [];
          var returnData = value.data;
          for (let i = 0; i < returnData.length; i++) {
            var tmap = {};
            tmap["id"] = returnData[i]["id"];
            tmap["text"] = returnData[i]["text"];
            tmap["icon"] = returnData[i]["icon"];
            tmap["isLeaf"] = !returnData[i]["children"];
            tmpData.push(tmap);
          }
          self.loading = false;
          return tmpData;
        })
        .catch(function (error) {
          if (error.length > 0) {
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          }
        });
      return data;
    },

    async loadItems() {
      var result = getMemberInfo();
      var self = this;
      this.isLoading = true;
      var returnData;
      returnData = await result
        .then(async function (value) {
          self.uid = value.data.data.id;
          self.testData = await self.getTreeData();
          // self.total_member = value.data.data.team_member;
          return self.testData;
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
      return returnData;
    },
    },
  };
  </script>
  
<style scoped>



  .text_main_title{
    flex-grow: 1;
    text-align: center;
  }
  .text{
    color: var(--Main, #FFF);
    font-family: Poppins;
    font-style: normal;
  }
  
  .main_text{
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin-top: 7px;
  }
  
  .header {
    display: flex;
    /* padding: 0px 16px; */
    margin-top: 4rem !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }


  .card {
    border: 1px solid var(--Neon-Blue, #0BDDCC);
    border-radius: 9px;
  }

  </style>
  